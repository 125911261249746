// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

    static targets = ['grid','more'];
    static values = { url: String, offset:Number, intervall:Number }

    connect() {
        useIntersection(this, { eventPrefix: false, element: this.moreTarget })
    }

    loadMore() {
        if(this.finishedValue) return
        this.moreTarget.classList.remove("opacity-0")

        this.offsetValue = parseInt(this.offsetValue)+1

        const url = this.urlValue+"?offset="+(this.offsetValue);

        fetch(url).then((response) => {
            response.text().then((text) => {

                const checkdiv = document.createElement("div");
                checkdiv.innerHTML = text

                const countElements = checkdiv.querySelectorAll("a")

                this.gridTarget.innerHTML += text
                this.moreTarget.classList.add("opacity-0")

                this.lazyImages();

                if(countElements.length==8) {

                    this.intervallValue = setTimeout(() => {
                        this.loadMore()
                    }, 1000)

                } else {
                    this.loadMoreStop()
                    this.finishedValue = true
                }

            })
        })
    }

    loadMoreStop() {

        clearTimeout(this.intervallValue);
        this.moreTarget.classList.add("opacity-0")
    }


    lazyImages() {
        var lazyImages = [].slice.call(document.querySelectorAll("img.lazy-start"));

        if ("IntersectionObserver" in window) {
            let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove("lazy-start");
                        lazyImage.classList.add("lazyloaded");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            lazyImages.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        } else {
            // Possibly fall back to event handlers here
        }
    }

}