// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

export default class extends Controller {

    static targets = ['swiper', 'dots', 'descriptions'];

    static values = {active: Boolean}

    initialize() {

        this.start();
    }


    start() {

        if (this.activeValue) return;

        const sliderlength = this.swiperTarget.querySelectorAll(".swiper-slide").length;

        var swiper = new Swiper(this.swiperTarget, {
            effect: "fade",
            simulateTouch: true,
            loop: false
        });

        for (let i = 0; i < sliderlength; i++) {
            let dot = document.createElement('div');
            dot.classList.add("dot");
            if (i == 0) dot.classList.add("active");
            let dotnumber = document.createElement('div');
            dotnumber.textContent = i + 1;
            dot.dataset.index = i;

            this.boundDotClick = this.dotclick.bind(this)
            dot.addEventListener("click", this.boundDotClick);

            dot.appendChild(dotnumber);
            this.dotsTarget.appendChild(dot);
        }

        swiper.on('activeIndexChange', () => {
            const all_descriptions = this.descriptionsTarget.querySelectorAll(".image-description");
            for (const description_element of all_descriptions) {
                description_element.classList.add("hidden");
            }



            all_descriptions[this.swiperTarget.swiper.activeIndex].classList.remove("hidden");

            const all_dots = this.dotsTarget.querySelectorAll(".dot");
            for (const dot of all_dots) {
                dot.classList.remove("active");
            }
            all_dots[this.swiperTarget.swiper.activeIndex].classList.add("active");
        });

        this.activeValue = true

    }

    dotclick(event) {
        let i = event.target.dataset.index;
        this.swiperTarget.swiper.slideTo(i);
    }

    next() {
        let width = window.innerWidth;
        if (window.innerWidth > 744) {

            if(this.swiperTarget.swiper.activeIndex == this.swiperTarget.querySelectorAll(".swiper-slide").length-1) {
                this.swiperTarget.swiper.slideTo(0);
            } else {
                this.swiperTarget.swiper.slideNext();
            }

        }




    }

}