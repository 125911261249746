// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ['bg','main', 'toggler', 'innerMenu1', 'innerMenu2', 'innerMenu3', 'showSearch', 'closeSearch', 'navcontent', 'fakebg'];

    static values = { active: Boolean }

    connect () {
        this.initialized = true
    }

    hidenavbutton() {
        this.togglerTarget.classList.add("!hidden")
        this.navcontentTarget.classList.add("!hidden")
        this.fakebgTarget.classList.remove("hidden")
    }

    shownavbutton() {
        this.togglerTarget.classList.remove("!hidden")
        this.navcontentTarget.classList.remove("!hidden")
        this.fakebgTarget.classList.add("hidden")
    }

    toggleMenu() {

        this.activeValue = !this.activeValue

        if (this.activeValue) {
            this.showMenu()
        } else {
            this.hideMenu()
        }

    }

    showSearch() {

        this.showSearchTarget.classList.add("hidden");
        this.closeSearchTarget.classList.remove("hidden");

        const trigger = new CustomEvent("trigger-showSearch");
        window.dispatchEvent(trigger);
    }

    closeSearch() {

        this.showSearchTarget.classList.remove("hidden");
        this.closeSearchTarget.classList.add("hidden");

        const trigger = new CustomEvent("trigger-closeSearch");
        window.dispatchEvent(trigger);
    }

    showMenu() {
        // prevent body from scrolling while drawer is open
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'

        this.mainTarget.classList.remove('hidden');
        this.bgTarget.classList.remove('hidden');

        setTimeout(() => {
            document.body.classList.add("menu-open");
            this.bgTarget.classList.remove('opacity-0');
            this.mainTarget.classList.remove('translate-x-full');
            this.togglerTarget.querySelector(".menu-open").classList.add("hidden");
            this.togglerTarget.querySelector(".menu-close").classList.remove("hidden");
            this.togglerTarget.querySelector(".menu-text").classList.add("text-weiss");
            this.togglerTarget.classList.add("bg-off-schwarz");
            this.innerMenu1Target.classList.remove('md:!translate-x-[200%]');
            this.innerMenu2Target.classList.remove('md:!translate-x-[100%]');
        }, 50)
    }

    hideMenu() {
        // dont do this before initialization
        // otherwise body scroll to top first and anchor links dont work
        if (this.initialized) {
            // restore scrollable body
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);


            this.bgTarget.classList.add('opacity-0');

            this.togglerTarget.querySelector(".menu-open").classList.remove("hidden");
            this.togglerTarget.querySelector(".menu-close").classList.add("hidden");
            this.togglerTarget.querySelector(".menu-text").classList.remove("text-weiss");
            this.togglerTarget.classList.remove("bg-off-schwarz");


            document.body.classList.remove("menu-open");
            this.mainTarget.classList.add('translate-x-full');
            this.innerMenu1Target.classList.add('md:!translate-x-[200%]');
            this.innerMenu2Target.classList.add('md:!translate-x-[100%]');
            this.mainTarget.addEventListener('transitionend', () => {
                this.bgTarget.classList.add('hidden')
                this.mainTarget.classList.add('hidden')
            }, {once: true});
        }
    }
}