import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['soundcloud','toggle','toggleInner'];

    static values = {
        active: Boolean,
    }

    connect() {

        let showAllSoundcloud = parseInt(Cookies.get('showAllSoundcloud'));

        if(showAllSoundcloud==1) {
            this.loadSoundcloudInitial();
        }

    }

    loadSoundcloudInitial() {

        let embed = this.soundcloudTarget.getAttribute("data-embed");
        this.soundcloudTarget.classList.remove("p-4");
        this.soundcloudTarget.classList.remove("md:aspect-[16/9]");

        setTimeout(() => {
            this.soundcloudTarget.innerHTML = embed;
        }, 500)

    }

    loadSoundcloud() {

        let embed = this.soundcloudTarget.getAttribute("data-embed");
        this.soundcloudTarget.innerHTML = embed;
        this.soundcloudTarget.classList.remove("p-4");
        this.soundcloudTarget.classList.remove("md:aspect-[16/9]");

    }

    setCookie() {
        let showAllSoundcloud = parseInt(Cookies.get('showAllSoundcloud'));

        if(showAllSoundcloud==1) {
            Cookies.set('showAllSoundcloud', "0", { expires: 365 }) ;
            this.toggleTarget.classList.remove("bg-orange-aktiv");
            this.toggleTarget.classList.add("bg-switch");
            this.toggleInnerTarget.classList.remove("translate-x-5");
        } else {
            Cookies.set('showAllSoundcloud', "1", { expires: 365 });
            this.toggleTarget.classList.add("bg-orange-aktiv");
            this.toggleTarget.classList.remove("bg-switch");
            this.toggleInnerTarget.classList.add("translate-x-5");
        }



    }



}