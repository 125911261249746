// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"

import Swiper from 'swiper/bundle';
// import styles bundle
import 'swiper/css/bundle';

export default class extends Controller {

    static targets = ['searchContainer', 'searchfield', 'resultsProjectHolder', 'resultsProject', 'resultsArticlesHolder', 'resultsArticles', 'gridLoader', 'resultsTmpHolder', 'resultsProjectEntries', 'resultsArticlesEntries','resultsCount'];

    static values = {
        active: Boolean,
        timeout: Number,
    }

    connect() {

        this.start();
    }


    start() {



    }

    showSearch() {
        this.searchContainerTarget.classList.remove("hidden")
        this.searchfieldTarget.focus()
        const trigger = new CustomEvent("trigger-hidenavbutton");
        console.log(1)
        window.dispatchEvent(trigger);
    }

    closeSearch() {
        this.searchContainerTarget.classList.add("hidden")
        const trigger = new CustomEvent("trigger-shownavbutton");
        window.dispatchEvent(trigger);
    }

    search() {

        clearTimeout(this.timeoutValue);

        this.resultsProjectHolderTarget.classList.add("hidden");
        this.resultsArticlesHolderTarget.classList.add("hidden");
        this.gridLoaderTarget.classList.remove("opacity-0")
        this.resultsCountTarget.classList.add("hidden")

        this.timeoutValue = setTimeout(() => {
            if (this.searchfieldTarget.value == "") {


                this.resultsProjectTarget.innerHTML = "";
                this.resultsArticlesTarget.innerHTML = "";
                this.resultsCountTarget.classList.add("hidden")
                this.resultsProjectHolderTarget.classList.add("hidden");
                this.resultsArticlesHolderTarget.classList.add("hidden");
                return
            }



            let result = this.resultTarget

            let url = "/search/ajax?s="+this.searchfieldTarget.value

            fetch(url).then((response) => {
                response.text().then((text) => {

                    this.gridLoaderTarget.classList.add("opacity-0")

                    this.resultsTmpHolderTarget.innerHTML = text;

                    this.resultsProjectTarget.innerHTML = this.resultsProjectEntriesTarget.innerHTML;
                    this.resultsArticlesTarget.innerHTML = this.resultsArticlesEntriesTarget.innerHTML;

                    if(this.resultsProjectTarget.querySelectorAll("a").length>0) {
                        this.resultsProjectHolderTarget.classList.remove("hidden");
                    }
                    if(this.resultsArticlesTarget.querySelectorAll("a").length>0) {
                        this.resultsArticlesHolderTarget.classList.remove("hidden");
                    }

                    this.resultsCountTarget.innerHTML = (this.resultsProjectTarget.querySelectorAll("a").length+this.resultsArticlesTarget.querySelectorAll("a").length)+" Results";
                    this.resultsCountTarget.classList.remove("hidden")

                    if((this.resultsProjectTarget.querySelectorAll("a").length+this.resultsArticlesTarget.querySelectorAll("a").length)==0) {
                        this.resultsProjectHolderTarget.classList.add("hidden");
                        this.resultsArticlesHolderTarget.classList.add("hidden");
                    }

                    this.lazyImages();

                })
            })

        },100);
    }

    lazyImages() {
        var lazyImages = [].slice.call(document.querySelectorAll("img.lazy-start"));

        if ("IntersectionObserver" in window) {
            let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove("lazy-start");
                        lazyImage.classList.add("lazyloaded");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            lazyImages.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        } else {
            // Possibly fall back to event handlers here
        }
    }

}