import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {

    static targets = ['video','toggle','toggleInner'];

    static values = {
        active: Boolean,
    }

    connect() {

        let showAllVideosVimeo = parseInt(Cookies.get('showAllVideosVimeo'));

        if(showAllVideosVimeo==1) {
            this.loadVideoInitial();
        }

    }

    loadVideoInitial() {

        let videoId = this.videoTarget.getAttribute("data-id");

        this.videoTarget.classList.remove("aspect-auto");
        this.videoTarget.classList.remove("md:aspect-[16/9]");
        this.videoTarget.classList.add("aspect-[16/9]");

        setTimeout(() => {
            this.videoTarget.innerHTML = '<iframe class="absolute top-0 left-0 w-full h-full" src="https://player.vimeo.com/video/'+videoId+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';
        }, 500)

    }


    loadVideo() {

        let videoId = this.videoTarget.getAttribute("data-id");

        this.videoTarget.classList.remove("aspect-auto");
        this.videoTarget.classList.remove("md:aspect-[16/9]");
        this.videoTarget.classList.add("aspect-[16/9]");

        this.videoTarget.innerHTML = '<iframe class="absolute top-0 left-0 w-full h-full" src="https://player.vimeo.com/video/'+videoId+'?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>';

    }

    setCookie() {
        let showAllVideosVimeo = parseInt(Cookies.get('showAllVideosVimeo'));

        if(showAllVideosVimeo==1) {
            Cookies.set('showAllVideosVimeo', "0", { expires: 365 }) ;
            this.toggleTarget.classList.remove("bg-orange-aktiv");
            this.toggleTarget.classList.add("bg-switch");
            this.toggleInnerTarget.classList.remove("translate-x-5");
        } else {
            Cookies.set('showAllVideosVimeo', "1", { expires: 365 });
            this.toggleTarget.classList.add("bg-orange-aktiv");
            this.toggleTarget.classList.remove("bg-switch");
            this.toggleInnerTarget.classList.add("translate-x-5");
        }



    }



}