// src/controllers/main_navigation_controller.js
import { Controller } from "@hotwired/stimulus"
import { useIntersection } from 'stimulus-use'

export default class extends Controller {

    static targets = ['gridLoader', 'grid', 'filterLocations', 'filterTopics', 'filterProfessionalAreas', 'filterCountry', 'filterLanguages', 'filterLocationsMobile', 'filterTopicsMobile', 'filterProfessionalAreasMobile', 'filterCountryMobile', 'filterLanguagesMobile', 'tags', 'tagsOuter', 'filterContainerMobile'];
    static values = { locations: String, professionalareas: String, topics: String, languages: String, country: String, url: String, urltag: String }

    connect() {

        this.initialized = true

        this.loadFilterLocations();

        this.loadFilterProfessionalAreas();

        this.loadFilterTopics();

        this.loadFilterCountry();

        this.loadFilterLanguages();

        this.loadFilterResult();

        document.addEventListener('click', function (event) {

            if(event.target.classList.contains("filter-button")) {
                event.preventDefault();
                return;
            }

            if(event.target.closest(".filter-dropdown")!=null) {
                //event.preventDefault();
                //return;
            }

            let filterOpen = document.querySelectorAll(".filter-dropdown");
            filterOpen.forEach(function (fo) {
                fo.classList.add("hidden")
            });

            let filterButtons = document.querySelectorAll(".filter-button");
            filterButtons.forEach(function (fb) {
                fb.classList.remove("highlight")
            });

        });



    }

    loadFilterLocations() {

        let url = this.urlValue + "locations" + this.buildParams();

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.filterLocationsTarget.innerHTML = text;
                this.filterLocationsMobileTarget.innerHTML = text;

            });
        });

    }

    loadFilterProfessionalAreas() {

        let url = this.urlValue + "professionalAreas" + this.buildParams();

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.filterProfessionalAreasTarget.innerHTML = text;
                this.filterProfessionalAreasMobileTarget.innerHTML = text;

            });
        });

    }

    loadFilterTopics() {

        let url = this.urlValue + "topics" + this.buildParams();

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.filterTopicsTarget.innerHTML = text;
                this.filterTopicsMobileTarget.innerHTML = text;

            });
        });

    }

    loadFilterCountry() {

        let url = this.urlValue + "country" + this.buildParams();

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.filterCountryTarget.innerHTML = text;
                this.filterCountryMobileTarget.innerHTML = text;

            });
        });

    }

    loadFilterLanguages() {

        let url = this.urlValue + "languages" + this.buildParams();

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.filterLanguagesTarget.innerHTML = text;
                this.filterLanguagesMobileTarget.innerHTML = text;

            });
        });

    }

    loadFilterResult() {

        let url = this.urlValue + "filter" + this.buildParams();

        this.gridLoaderTarget.classList.remove("opacity-0")
        this.gridTarget.classList.add("opacity-20")

        fetch(url).then((response) => {
            response.text().then((text) => {


                this.gridTarget.innerHTML = text;

                this.lazyImages();

                this.gridLoaderTarget.classList.add("opacity-0")
                this.gridTarget.classList.remove("opacity-20")

            });
        });

        this.tagsTarget.innerHTML = "";

        if(this.locationsValue != "" || this.countryValue != "" || this.professionalareasValue != "" || this.topicsValue != "" || this.languagesValue != "") {
            this.tagsOuterTarget.classList.remove("hidden");
            let tagstarget = this.tagsTarget;

            let eltmpstr = '<div class="filter-tag" data-action="click->filterPeople#removeFilter"><span class="label pointer-events-none"></span><span class="icon pointer-events-none"><svg class="h-4 w-4"><use xlink:href="/assets/images/sprite.svg#icon-filter-close" class="filter-close"></use></svg></span></div>';


            if(this.locationsValue != "") {
                let locs = this.locationsValue.split(",");
                let url = this.urltagValue+"/locations?slug="

                locs.forEach(function (val) {

                    var label=val;
                    fetch(url+val).then((response) => {
                        response.text().then((label) => {
                            var parser = new DOMParser();
                            var eltmp = parser.parseFromString(eltmpstr, 'text/html');

                            var el = eltmp.querySelector(".filter-tag");
                            el.querySelector(".label").innerHTML = label;
                            el.setAttribute('data-value', val);
                            el.setAttribute('data-type', "locations");

                            tagstarget.appendChild(el);
                        });
                    });


                });
            }

            if(this.topicsValue != "") {
                let topics = this.topicsValue.split(",");
                let url = this.urltagValue+"/topics?slug="

                topics.forEach(function (val) {

                    var label=val;
                    fetch(url+val).then((response) => {
                        response.text().then((label) => {
                            var parser = new DOMParser();
                            var eltmp = parser.parseFromString(eltmpstr, 'text/html');

                            var el = eltmp.querySelector(".filter-tag");
                            el.querySelector(".label").innerHTML = label;
                            el.setAttribute('data-value', val);
                            el.setAttribute('data-type', "topics");

                            tagstarget.appendChild(el);
                        });
                    });

                });
            }

            if(this.professionalareasValue != "") {
                let professionalAreas = this.professionalareasValue.split(",");
                let url = this.urltagValue+"/professionalAreas?slug="

                professionalAreas.forEach(function (val) {

                    var label=val;
                    fetch(url+val).then((response) => {
                        response.text().then((label) => {
                            var parser = new DOMParser();
                            var eltmp = parser.parseFromString(eltmpstr, 'text/html');

                            var el = eltmp.querySelector(".filter-tag");
                            el.querySelector(".label").innerHTML = label;
                            el.setAttribute('data-value', val);
                            el.setAttribute('data-type', "professionalAreas");

                            tagstarget.appendChild(el);
                        });
                    });


                });
            }

            if(this.countryValue != "") {
                let countries = this.countryValue.split(",");
                let url = this.urltagValue+"/country?slug="

                countries.forEach(function (val) {

                    var label=val;
                    fetch(url+val).then((response) => {
                        response.text().then((label) => {
                            var parser = new DOMParser();
                            var eltmp = parser.parseFromString(eltmpstr, 'text/html');

                            var el = eltmp.querySelector(".filter-tag");
                            el.querySelector(".label").innerHTML = label;
                            el.setAttribute('data-value', val);
                            el.setAttribute('data-type', "country");

                            tagstarget.appendChild(el);
                        });
                    });


                });
            }

            if(this.languagesValue != "") {
                let languages = this.languagesValue.split(",");
                let url = this.urltagValue+"/languages?slug="

                languages.forEach(function (val) {

                    var label=val;
                    fetch(url+val).then((response) => {
                        response.text().then((label) => {
                            var parser = new DOMParser();
                            var eltmp = parser.parseFromString(eltmpstr, 'text/html');

                            var el = eltmp.querySelector(".filter-tag");
                            el.querySelector(".label").innerHTML = label;
                            el.setAttribute('data-value', val);
                            el.setAttribute('data-type', "languages");

                            tagstarget.appendChild(el);
                        });
                    });


                });
            }

        } else {
            this.tagsOuterTarget.classList.add("hidden");
        }

    }

    changeUrl() {
        let original_url = document.location.href;

        const state = {  };

        history.pushState(state, "", "/network/people" + this.buildParams());
    }

    buildParams() {

        let par = [];
        par.push("location="+this.locationsValue);
        par.push("country="+this.countryValue);
        par.push("topic="+this.topicsValue);
        par.push("professionalArea="+this.professionalareasValue);
        par.push("language="+this.languagesValue);
        return "?"+par.join("&");

    }

    removeFilter(event) {
        const rval = event.target.getAttribute("data-value");
        const type = event.target.getAttribute("data-type");

        if(type=="professionalAreas") {
            let nprofessionalAreas = [];
            let professionalAreas = this.professionalareasValue.split(",");
            professionalAreas.forEach(function (val) {
                if(rval!=val) {
                    nprofessionalAreas.push(val)
                }
            });
            this.professionalareasValue = nprofessionalAreas.join(",")
        }
        if(type=="topics") {
            let ntopics = [];
            let topics = this.topicsValue.split(",");
            topics.forEach(function (val) {
                if(rval!=val) {
                    ntopics.push(val)
                }
            });
            this.topicsValue = ntopics.join(",")
        }
        if(type=="locations") {
            let nlocations = [];
            let locations = this.locationsValue.split(",");
            locations.forEach(function (val) {
                if(rval!=val) {
                    nlocations.push(val)
                }
            });
            this.locationsValue = nlocations.join(",")
        }
        if(type=="country") {
            let ncountry = [];
            let country = this.countryValue.split(",");
            country.forEach(function (val) {
                if(rval!=val) {
                    ncountry.push(val)
                }
            });
            this.countryValue = ncountry.join(",")
        }
        if(type=="languages") {
            let nlanguages = [];
            let languages = this.languagesValue.split(",");
            languages.forEach(function (val) {
                if(rval!=val) {
                    nlanguages.push(val)
                }
            });
            this.languagesValue = nlanguages.join(",")
        }

        event.target.remove()
        this.reloadall();
    }

    resetFilter() {

        var filteritems = document.querySelectorAll(".filter-item");
        filteritems.forEach(function (fb) {
            fb.classList.remove("checked")
        });

        this.professionalareasValue = "";
        this.topicsValue = "";
        this.locationsValue = "";
        this.countryValue = "";
        this.languagesValue = "";

        this.closeFilterContainerMobile();
        this.reloadall();
    }

    openFilterContainerMobile() {

        // prevent body from scrolling while drawer is open
        document.body.style.top = `-${window.scrollY}px`
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.width = '100%'

        this.filterContainerMobileTarget.classList.remove("hidden");
        setTimeout(() => {
            this.filterContainerMobileTarget.classList.remove("translate-x-full");
        }, 50)
    }

    closeFilterContainerMobile() {

        let filterOpen = document.querySelectorAll(".filter-accordion-content");
        filterOpen.forEach(function (fo) {
            fo.classList.add("hidden")
        });

        let filterButtons = document.querySelectorAll(".filter-accordion");
        filterButtons.forEach(function (fb) {
            fb.classList.remove("open")
        });

        if (this.initialized) {
            // restore scrollable body
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.overflowY = ''
            document.body.style.width = ''

            window.scrollTo(0, parseInt(scrollY || '0') * -1);


            this.filterContainerMobileTarget.classList.add("translate-x-full");

            this.filterContainerMobileTarget.addEventListener('transitionend', () => {
                this.filterContainerMobileTarget.classList.add("hidden");
            }, {once: true});

        }
    }

    openFilter(event) {
        //console.log(event.target.parentNode.querySelector('.filter-dropdown'));

        let cur_fo = event.target.parentNode.querySelector('.filter-dropdown');
        let cur_fb = event.target;

        let filterOpen = document.querySelectorAll(".filter-dropdown");
        filterOpen.forEach(function (fo) {
            if(fo != cur_fo) {
                fo.classList.add("hidden")
            }
        });

        let filterButtons = document.querySelectorAll(".filter-button");
        filterButtons.forEach(function (fb) {
            if(fb != cur_fb) {
                fb.classList.remove("highlight")
            }
        });

        event.target.parentNode.querySelector('.filter-dropdown').classList.toggle("hidden")
        event.target.classList.toggle("highlight")
    }

    openFilterMobile(event) {
        //console.log(event.target.parentNode.querySelector('.filter-dropdown'));

        let cur_fo = event.target.parentNode.querySelector('.filter-accordion-content');
        let cur_fb = event.target;

        let filterOpen = document.querySelectorAll(".filter-accordion-content");
        filterOpen.forEach(function (fo) {
            if(fo != cur_fo) {
                fo.classList.add("hidden")
            }
        });

        let filterButtons = document.querySelectorAll(".filter-accordion");
        filterButtons.forEach(function (fb) {
            if(fb != cur_fb) {
                fb.classList.remove("open")
            }
        });

        event.target.parentNode.querySelector('.filter-accordion-content').classList.toggle("hidden")
        event.target.classList.toggle("open")
    }

    reloadall() {
        this.loadFilterResult();

        this.loadFilterLocations();

        this.loadFilterProfessionalAreas();

        this.loadFilterTopics();

        this.loadFilterCountry();

        this.loadFilterLanguages();

        this.changeUrl()
    }

    filterLocation(event) {
        event.target.classList.toggle("checked");
        let els = event.target.closest(".filter-filter-content").querySelectorAll(".checked");

        let query = [];

        els.forEach(function (el) {
            query.push(el.getAttribute('data-slug'))
        });

        this.locationsValue = query.join(",")

        this.reloadall();
    }

    filterProfessionalArea(event) {
        event.target.classList.toggle("checked");
        let els = event.target.closest(".filter-filter-content").querySelectorAll(".checked");

        let query = [];

        els.forEach(function (el) {
            query.push(el.getAttribute('data-slug'))
        });

        this.professionalareasValue = query.join(",")

        this.reloadall();
    }

    filterTopic(event) {
        event.target.classList.toggle("checked");
        let els = event.target.closest(".filter-filter-content").querySelectorAll(".checked");

        let query = [];

        els.forEach(function (el) {
            query.push(el.getAttribute('data-slug'))
        });

        this.topicsValue = query.join(",")

        this.reloadall();
    }

    filterCountry(event) {
        event.target.classList.toggle("checked");
        let els = event.target.closest(".filter-filter-content").querySelectorAll(".checked");

        let query = [];

        els.forEach(function (el) {
            query.push(el.getAttribute('data-slug'))
        });

        this.countryValue = query.join(",")

        this.reloadall();
    }

    filterLanguage(event) {
        event.target.classList.toggle("checked");
        let els = event.target.closest(".filter-filter-content").querySelectorAll(".checked");

        let query = [];

        els.forEach(function (el) {
            query.push(el.getAttribute('data-slug'))
        });

        this.languagesValue = query.join(",")

        this.reloadall();
    }

    lazyImages() {
        var lazyImages = [].slice.call(document.querySelectorAll("img.lazy-start"));

        if ("IntersectionObserver" in window) {
            let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    if (entry.isIntersecting) {
                        let lazyImage = entry.target;
                        lazyImage.src = lazyImage.dataset.src;
                        lazyImage.srcset = lazyImage.dataset.srcset;
                        lazyImage.classList.remove("lazy-start");
                        lazyImage.classList.add("lazyloaded");
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            lazyImages.forEach(function(lazyImage) {
                lazyImageObserver.observe(lazyImage);
            });
        } else {
            // Possibly fall back to event handlers here
        }
    }

}